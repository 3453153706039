import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kurz-privacy-policy',
  templateUrl: './kurz-privacy-policy.component.html',
  styleUrls: ['./kurz-privacy-policy.component.scss']
})
export class KurzPrivacyPolicyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
